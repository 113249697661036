import React, { useContext } from "react"
import { Link, NavLink } from "react-router-dom";
import logo from "../img/logo.svg"
import "../styles/nav.css"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../context/auth";
import logout from "../img/icons/logout.svg"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import users from "../img/icons/users.svg"
import usersFilled from "../img/icons/users_filled.svg"
import sucursales from "../img/icons/sucursales.svg";
import sucursalesFilled from "../img/icons/sucursales_filled.svg";
import diasFilled from "../img/icons/dias_filled.svg";
import dias from "../img/icons/dias.svg";
import horarios from "../img/icons/horarios.svg";
import horariosFilled from "../img/icons/horarios_filled.svg";
import alumnosFilled from "../img/icons/alumnos_filled.svg";
import alumnos from "../img/icons/alumnos.svg";
import cuotasFilled from "../img/icons/cuotas_filled.svg";
import cuotas from "../img/icons/cuotas.svg";
import asistenciasFilled from "../img/icons/asistencias_filled.svg";
import asistencias from "../img/icons/asistencias.svg";

const Nav = ({ children }) => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleCloseSesion = () => {
        localStorage.clear();
        navigate("/");
    }

    return <div className="contenedorMain">
        <header>
            <div className="menuHeader">
                <IconButton onClick={toggleDrawer(true)}>
                    <MenuIcon style={{ fontSize: 30 }} />
                </IconButton>
            </div>
            <div className="userInfo">
                <div className="profile">
                    {(user && user.nombre !== "") ?
                        <><p>{user.nombre[0] + user.apellido[0]}</p>
                            <div className="profile_info">
                                <p>{user.nombre + " " + user.apellido}</p>
                                <p>{user.email}</p>
                            </div></>
                        : ""
                    }
                </div>
            </div>
            <div className="logoMobile">
                <img src={logo} className="logo" alt="Logo" />
            </div>
        </header>
        <main>{children}</main>
        <div className="containerNav">
            <Link to="/usuarios">
                <img src={logo} className="logo" alt="Logo" />
            </Link>
            {(user && user.tipo === "admin") ? <>
                <NavLink
                    to="/usuarios"
                >
                    {({ isActive }) => (
                        <div className="iconGroup">
                            <img src={isActive ? usersFilled : users} alt="Icono de cuotas" />
                            <p className={isActive ? "link-active" : ""}>Usuarios</p>
                        </div>
                    )}
                </NavLink>
                <NavLink
                    to="/sucursales"
                >
                    {({ isActive }) => (
                        <div className="iconGroup">
                            <img src={isActive ? sucursalesFilled : sucursales} alt="Icono de cuotas" />
                            <p className={isActive ? "link-active" : ""}>Sucursales</p>
                        </div>
                    )}
                </NavLink>
                <NavLink
                    to="/dias"
                >
                    {({ isActive }) => (
                        <div className="iconGroup">
                            <img src={isActive ? diasFilled : dias} alt="Icono de cuotas" />
                            <p className={isActive ? "link-active" : ""}>Dias</p>
                        </div>
                    )}
                </NavLink>
                <NavLink
                    to="/horarios"
                >
                    {({ isActive }) => (
                        <div className="iconGroup">
                            <img src={isActive ? horariosFilled : horarios} alt="Icono de cuotas" />
                            <p className={isActive ? "link-active" : ""}>Horarios</p>
                        </div>
                    )}
                </NavLink>
                <NavLink
                    to="/alumnos"
                >
                    {({ isActive }) => (
                        <div className="iconGroup">
                            <img src={isActive ? alumnosFilled : alumnos} alt="Icono de cuotas" />
                            <p className={isActive ? "link-active" : ""}>Alumnos</p>
                        </div>
                    )}
                </NavLink>
                <NavLink
                    to="/cuotas"
                >
                    {({ isActive }) => (
                        <div className="iconGroup">
                            <img src={isActive ? cuotasFilled : cuotas} alt="Icono de cuotas" />
                            <p className={isActive ? "link-active" : ""}>Cuotas</p>
                        </div>
                    )}
                </NavLink>
                <NavLink
                    to="/asistencias"
                >
                    {({ isActive }) => (
                        <div className="iconGroup">
                            <img src={isActive ? asistenciasFilled : asistencias} alt="Icono de cuotas" />
                            <p className={isActive ? "link-active" : ""}>Asistencias</p>
                        </div>
                    )}
                </NavLink>
            </> : <>
                <NavLink
                    to="/asistencias"
                >
                    {({ isActive }) => (
                        <div className="iconGroup">
                            <img src={isActive ? asistenciasFilled : asistencias} alt="Icono de cuotas" />
                            <p className={isActive ? "link-active" : ""}>Asistencias</p>
                        </div>
                    )}
                </NavLink>
            </>}
            <div className="iconGroup cerrarSesion" onClick={handleCloseSesion}>
                <img src={logout} alt="Icono de cerrar sesion" />
                <p>Cerrar sesión</p>
            </div>
        </div>
        <Drawer open={open} onClose={toggleDrawer(false)}>
            <div className="containerNavMobile">
                <Link to="/usuarios" style={{ display: "flex", justifyContent: "center" }} onClick={toggleDrawer(false)}>
                    <img src={logo} className="logo" alt="Logo" />
                </Link>
                <div className="profile">
                    {(user && user.nombre !== "") ?
                        <><p>{user.nombre[0] + user.apellido[0]}</p>
                            <div className="profile_info">
                                <p>{user.nombre + " " + user.apellido}</p>
                                <p>{(user.tipo === "admin") ? "Administrador" : "Profesor"}</p>
                            </div></>
                        : ""
                    }
                </div>
                {(user && user.tipo === "admin") ? <>
                    <NavLink
                        onClick={toggleDrawer(false)}
                        to="/usuarios"
                    >
                        {({ isActive }) => (
                            <div className="iconGroup">
                                <img src={isActive ? usersFilled : users} alt="Icono de cuotas" />
                                <p className={isActive ? "link-active" : ""}>Usuarios</p>
                            </div>
                        )}
                    </NavLink>
                    <NavLink
                        onClick={toggleDrawer(false)}
                        to="/sucursales"
                    >
                        {({ isActive }) => (
                            <div className="iconGroup">
                                <img src={isActive ? sucursalesFilled : sucursales} alt="Icono de cuotas" />
                                <p className={isActive ? "link-active" : ""}>Sucursales</p>
                            </div>
                        )}
                    </NavLink>
                    <NavLink
                        onClick={toggleDrawer(false)}
                        to="/dias"
                    >
                        {({ isActive }) => (
                            <div className="iconGroup">
                                <img src={isActive ? diasFilled : dias} alt="Icono de cuotas" />
                                <p className={isActive ? "link-active" : ""}>Dias</p>
                            </div>
                        )}
                    </NavLink>
                    <NavLink
                        onClick={toggleDrawer(false)}
                        to="/horarios"
                    >
                        {({ isActive }) => (
                            <div className="iconGroup">
                                <img src={isActive ? horariosFilled : horarios} alt="Icono de cuotas" />
                                <p className={isActive ? "link-active" : ""}>Horarios</p>
                            </div>
                        )}
                    </NavLink>
                    <NavLink
                        onClick={toggleDrawer(false)}
                        to="/alumnos"
                    >
                        {({ isActive }) => (
                            <div className="iconGroup">
                                <img src={isActive ? alumnosFilled : alumnos} alt="Icono de cuotas" />
                                <p className={isActive ? "link-active" : ""}>Alumnos</p>
                            </div>
                        )}
                    </NavLink>
                    <NavLink
                        onClick={toggleDrawer(false)}
                        to="/cuotas"
                    >
                        {({ isActive }) => (
                            <div className="iconGroup">
                                <img src={isActive ? cuotasFilled : cuotas} alt="Icono de cuotas" />
                                <p className={isActive ? "link-active" : ""}>Cuotas</p>
                            </div>
                        )}
                    </NavLink>
                    <NavLink
                        onClick={toggleDrawer(false)}
                        to="/asistencias"
                    >
                        {({ isActive }) => (
                            <div className="iconGroup">
                                <img src={isActive ? asistenciasFilled : asistencias} alt="Icono de cuotas" />
                                <p className={isActive ? "link-active" : ""}>Asistencias</p>
                            </div>
                        )}
                    </NavLink>
                </> : <>
                    <NavLink
                        onClick={toggleDrawer(false)}
                        to="/asistencias"
                    >
                        {({ isActive }) => (
                            <div className="iconGroup">
                                <img src={isActive ? asistenciasFilled : asistencias} alt="Icono de cuotas" />
                                <p className={isActive ? "link-active" : ""}>Asistencias</p>
                            </div>
                        )}
                    </NavLink>
                </>}
                <div className="iconGroup cerrarSesion" onClick={handleCloseSesion}>
                    <img src={logout} alt="Icono de cerrar sesion" />
                    <p>Cerrar sesión</p>
                </div>
            </div>
        </Drawer>
    </div>
}

export default Nav