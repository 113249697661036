import React, { useState, useEffect, useContext } from 'react';
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import {
    Box, Paper,
    IconButton,
    Tooltip,
    Typography,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
    FormControl,
    InputLabel,
    MenuItem,
    FormHelperText,
    Select
} from '@mui/material';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Axios from "axios"
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth";
import "../styles/modal.css";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import BtnSubmit from "../components/BtnSubmit"
import BtnLoading from "../components/BtnLoading"
import Message from "../components/Message";
import formatearPrecio from "../hooks/FormatearPrecio"
import FileUploadIcon from '@mui/icons-material/FileUpload';
import convertirFecha from "../hooks/convertirFecha"
import HistoryIcon from '@mui/icons-material/History';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Cuotas() {
    const navigate = useNavigate();
    const [creado, setCreado] = useState(false)
    const [cuotas, setCuotas] = useState([])
    const [alumnos, setAlumnos] = useState([])
    const [posiCuota, setPosiCuota] = useState(0)
    const { user } = useContext(AuthContext)
    const columns = [
        {
            field: 'alumno', headerName: 'Alumno', sortable: true, minWidth: 190, flex: 1,
            valueGetter: (value, row) => `${row.nombre || ''} ${row.apellido || ''}`
        },
        {
            field: 'fechaInicio', headerName: 'Fecha Inicio', sortable: true, minWidth: 170, flex: 1,
            valueGetter: (value, row) => `${convertirFecha(row.fechaInicio) || ''}`
        },
        {
            field: 'fechaFin', headerName: 'Fecha Fin', sortable: true, minWidth: 150, flex: 1,
            valueGetter: (value, row) => `${convertirFecha(row.fechaFin) || ''}`
        },
        {
            field: 'fechaProxPago', headerName: 'Fecha Prox Pago', sortable: true, minWidth: 150, flex: 1,
            valueGetter: (value, row) => `${convertirFecha(row.fechaProxPago) || ''}`
        },
        {
            field: 'metodoPago', headerName: 'Metodo de Pago', sortable: true, minWidth: 150, flex: 1
        },
        {
            field: 'nroComprobante', headerName: 'Nro comprobante', sortable: true, minWidth: 150, flex: 1
        },
        {
            field: 'precio', headerName: 'Precio', sortable: true, minWidth: 150, flex: 1,
            valueGetter: (value, row) => `Gs. ${formatearPrecio(row.precio.toString()) || ''}`
        },
        {
            field: 'estado', headerName: 'Estado', sortable: true, minWidth: 150, flex: 1
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            minWidth: 180,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                var posi = cuotas.findIndex(obj => obj.id === id);
                return [
                    <GridActionsCellItem
                        sx={{ display: (cuotas[posi].estado === "INACTIVA") ? "none" : "bloc" }}
                        icon={<Tooltip title="Editar cuota"><EditIcon /></Tooltip>}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            setPosiCuota(posi)
                            handleOpenModal(cuotas[posi], "Editar cuota");
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        sx={{ display: (cuotas[posi].estado === "INACTIVA") ? "none" : "bloc" }}
                        icon={<Tooltip title="Renovar cuota"><FileUploadIcon /></Tooltip>}
                        label="Renovar"
                        className="textPrimary"
                        onClick={() => {
                            setPosiCuota(posi)
                            handleOpenModal({ ...data, idAlumno: cuotas[posi].idAlumno }, "Renovar cuota");
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Ver pagos"><HistoryIcon /></Tooltip>}
                        label="History"
                        className="textPrimary"
                        onClick={() => {
                            navigate("/historialPagos/" + cuotas[posi].idAlumno)
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        sx={{ display: (cuotas[posi].estado === "INACTIVA") ? "bloc" : "none" }}
                        icon={<Tooltip title="Eliminar cuota"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        onClick={() => {
                            eliminarCuota(id, cuotas[posi].idAlumno)
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        sx={{ display: (cuotas[posi].estado === "INACTIVA") ? "none" : "bloc" }}
                        icon={<Tooltip title="Desactivar cuota"><DoNotDisturbOnIcon /></Tooltip>}
                        label="Delete"
                        onClick={() => {
                            establecerCuota(id, 0)
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        sx={{ display: (cuotas[posi].estado === "INACTIVA") ? "bloc" : "none" }}
                        icon={<Tooltip title="Activar cuota"><CheckCircleIcon /></Tooltip>}
                        label="Delete"
                        onClick={() => {
                            establecerCuota(id, 1)
                        }}
                        color="inherit"
                    />
                ];
            },
        },
    ];
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({
        fechaInicio: "",
        fechaFin: "",
        fechaProxPago: "",
        metodoPago: "",
        nroComprobante: "",
        idAlumno: "",
        precio: "",
        loading: false,
        errorFechaInicio: false,
        errorFechaFin: false,
        errorPrecio: false,
        errorFechaProxPago: false,
        errorMetodoPago: false,
        errorIdAlumno: false,
        nombreModal: "",
    });
    const { fechaInicio, fechaFin, metodoPago, nroComprobante,
        fechaProxPago, precio, idAlumno, loading, errorFechaInicio, errorFechaFin,
        errorPrecio, errorIdAlumno, errorFechaProxPago, errorMetodoPago, nombreModal } = data;
    const [textoMensaje, setTextoMensaje] = useState("")

    const handleOpenModal = (usuario, nombre) => {
        setOpenModal(true)
        setCreado(false);

        if (usuario) {
            usuario["nombreModal"] = nombre;
            setData(usuario);
        } else {
            setData({ ...data, nombreModal: nombre })
        }

    };

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenModal(false)
        setData({
            fechaInicio: "",
            fechaFin: "",
            idAlumno: "",
            precio: "",
            fechaProxPago: "",
            metodoPago: "",
            nroComprobante: "",
        });
    };

    useEffect(() => {
        if (user.token !== "") {
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/cuotas", {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    setCuotas(response.data.cuotas)
                }
            );
        }
        Axios.get(process.env.REACT_APP_API_BASE_URL + "/alumnos", {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(
            (response) => {
                setAlumnos(response.data.alumnos)
            }
        );
    }, [creado, user.token])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorFechaInicio: false,
            errorFechaFin: false,
            errorPrecio: false,
            errorIdAlumno: false,
            errorMetodoPago: false,
            errorFechaProxPago: false,
            loading: true,
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            errorFechaInicio: false,
            errorFechaFin: false,
            errorPrecio: false,
            errorIdAlumno: false,
            errorMetodoPago: false,
            errorFechaProxPago: false,
            loading: true,
        };

        Object.entries(data).forEach(([key, value]) => {
            if (value.length === 0 && (key === "precio" || key === "fechaProxPago" || key === "metodoPago" ||
                key === "fechaInicio" || key === "fechaFin" || key === "idAlumno")) {
                if (key === "idAlumno") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Selecciona un alumno";
                } else if (key === "fechaFin" || key === "fechaInicio" || key === "fechaProxPago") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa una fecha";
                } else if (key === "metodoPago") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un metodo de pago";
                } else {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un " + key;
                }
                tem["loading"] = false;
                completo = 0;
            }
        });

        if (!completo) {
            setData({
                ...data,
                errorFechaInicio: tem["errorFechaInicio"],
                errorFechaFin: tem["errorFechaFin"],
                errorPrecio: tem["errorPrecio"],
                errorMetodoPago: tem["errorMetodoPago"],
                errorFechaProxPago: tem["errorFechaProxPago"],
                errorIdAlumno: tem["errorIdAlumno"],
                loading: tem["loading"],
            });
        } else {
            if (nombreModal === "Editar cuota") {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/editar_cuota", {
                    idCuota: cuotas[posiCuota].id,
                    precio: parseInt(precio.toString().replaceAll(".", "")),
                    fechaFin: fechaFin,
                    fechaProxPago: fechaProxPago,
                    metodoPago: metodoPago,
                    idAlumno: idAlumno,
                    nroComprobante: nroComprobante,
                    fechaInicio: fechaInicio,
                }, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                }).then((response) => {
                    if (response.data.message) {
                        setCreado(!creado);
                        setTextoMensaje(response.data.message)
                        handleCloseModal();
                    }
                })
            } else if (nombreModal === "Renovar cuota") {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/renovar_cuota", {
                    idCuota: cuotas[posiCuota].id,
                    precio: parseInt(precio.toString().replaceAll(".", "")),
                    fechaInicio: fechaInicio,
                    fechaFin: fechaFin,
                    idAlumno: idAlumno,
                    fechaProxPago: fechaProxPago,
                    metodoPago: metodoPago,
                    usuario: user.nombre + " " + user.apellido,
                    nroComprobante: nroComprobante,
                }, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                }).then((response) => {
                    if (response.data.message) {
                        setCreado(!creado);
                        setTextoMensaje(response.data.message)
                        handleCloseModal();
                    }
                })
            } else {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/cuotas", {
                    idAlumno: idAlumno,
                    fechaFin: fechaFin,
                    fechaProxPago: fechaProxPago,
                    metodoPago: metodoPago,
                    nroComprobante: nroComprobante,
                    fechaInicio: fechaInicio,
                    precio: parseInt(precio.replaceAll(".", "")),
                    usuario: user.nombre + " " + user.apellido
                }, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                }).then((response) => {
                    if (response.data.error === "alumno") {
                        setData({
                            ...data,
                            errorIdAlumno: response.data.message,
                            loading: false
                        });
                    } else {
                        setCreado(!creado);
                        setTextoMensaje(response.data.message)
                        handleCloseModal();
                    }
                })
            }
        }
    }

    const eliminarCuota = (idCuota, idAlumno) => {
        if (window.confirm("Esta seguro de eliminar la cuota?")) {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/eliminar_cuota/", {
                idCuota: idCuota,
                idAlumno: idAlumno
            }, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    if (response.data.status === "error") {
                        alert("Error: " + response.data.message);
                    } else {
                        setCreado(!creado);
                        setTextoMensaje(response.data.message)
                    }
                }
            );
        }
    }

    const establecerCuota = (idCuota, activar) => {
        var pregunta = "Esta seguro de desactivar la cuota?";
        if (activar) {
            pregunta = "Esta seguro de activar la cuota?";
        }
        if (window.confirm(pregunta)) {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/establecer_cuota/", {
                idCuota: idCuota,
                activar: activar
            }, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    if (response.data.status === "error") {
                        alert("Error: " + response.data.message);
                    } else {
                        setCreado(!creado);
                        setTextoMensaje(response.data.message)
                    }
                }
            );
        }
    }

    function EditToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    flexDirection: "row",
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}>
                <div className="tablaHeader">
                    <Typography
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Cuotas
                    </Typography>
                    <Tooltip title="Añadir cuota">
                        <IconButton onClick={() => handleOpenModal({ ...data }, "Añadir cuota")}>
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <GridToolbarQuickFilter variant='outlined' size="small" />
            </GridToolbarContainer>
        );
    }


    return (
        <HelmetProvider>
            <Helmet>
                <title>Cuotas | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <Box sx={{ width: "100%" }}>
                {(creado) ? <Message texto={textoMensaje} /> : ""}
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <Dialog
                        className="modal modal_carpetas"
                        open={openModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseModal}
                        fullWidth={true}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {nombreModal}
                            <IconButton
                                onClick={handleCloseModal}
                                aria-label="close"
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent style={{ overflowY: "unset" }}>
                            <form onSubmit={handleSubmit}>
                                <FormControl
                                    sx={{ marginBottom: "22px" }}
                                    error={errorIdAlumno ? true : false}>
                                    <InputLabel id="label-usuario">Alumno</InputLabel>
                                    <Select
                                        labelId="label-usuario"
                                        label="Alumno"
                                        name="idAlumno"
                                        inputProps={{
                                            readOnly: (nombreModal === "Editar cuota" || nombreModal === "Renovar cuota"),
                                        }}
                                        value={idAlumno}
                                        onChange={handleChange}
                                    >
                                        {alumnos.map((alumno) => (
                                            <MenuItem key={alumno.id} value={alumno.id}>
                                                {alumno.nombre + " " + alumno.apellido}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{errorIdAlumno}</FormHelperText>
                                </FormControl>
                                <div className="textfield_group">
                                    <TextField
                                        name="fechaInicio"
                                        className="inputGroup"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleChange}
                                        value={fechaInicio}
                                        label="Fecha inicio"
                                        error={errorFechaInicio ? true : false}
                                        helperText={errorFechaInicio} />
                                    <TextField
                                        name="fechaFin"
                                        sx={{ minWidth: "200px" }}
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleChange}
                                        value={fechaFin}
                                        label="Fecha fin"
                                        error={errorFechaFin ? true : false}
                                        helperText={errorFechaFin} />
                                </div>
                                <div className="textfield_group">
                                    <TextField
                                        name="fechaProxPago"
                                        className="inputGroup"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleChange}
                                        value={fechaProxPago}
                                        label="Fecha Prox Pago"
                                        error={errorFechaProxPago ? true : false}
                                        helperText={errorFechaProxPago} />
                                    <FormControl
                                        sx={{ minWidth: "200px" }}
                                        error={errorMetodoPago ? true : false}>
                                        <InputLabel id="label-usuario">Metodo de pago</InputLabel>
                                        <Select
                                            labelId="label-usuario"
                                            label="Metodo de pago"
                                            name="metodoPago"
                                            value={metodoPago}
                                            onChange={handleChange}
                                        >
                                            <MenuItem key={"efectivo"} value={"efectivo"}>
                                                Efectivo
                                            </MenuItem>
                                            <MenuItem key={"transferencia"} value={"transferencia"}>
                                                Transferencia
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText>{errorMetodoPago}</FormHelperText>
                                    </FormControl>
                                </div>
                                <TextField
                                    sx={{ marginBottom: "22px" }}
                                    name="precio"
                                    onChange={handleChange}
                                    value={formatearPrecio(precio.toString())}
                                    label="Precio"
                                    error={errorPrecio ? true : false}
                                    helperText={errorPrecio} />
                                <TextField
                                    sx={{ marginBottom: "22px" }}
                                    name="nroComprobante"
                                    onChange={handleChange}
                                    value={nroComprobante}
                                    label="Nro Comprobante" />
                                {loading
                                    ? <BtnLoading
                                        texto={(nombreModal === "Editar cuota") ? "Editando..." : (nombreModal === "Renovar cuota") ? "Renovando..." : "Añadiendo..."} />
                                    : <BtnSubmit
                                        texto={(nombreModal === "Editar cuota") ? "Editar" : (nombreModal === "Renovar cuota") ? "Renovar" : "Añadir"} />
                                }
                            </form>
                        </DialogContent>
                    </Dialog>
                    <DataGrid
                        autoHeight
                        rows={cuotas}
                        columns={columns}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        getCellClassName={(params) => {
                            if (params.field === 'estado' && params.value === "VIGENTE") {
                                return 'text_activo';
                            } else if (params.field === 'estado' && params.value === "VENCIDA") {
                                return "text_desactivo";
                            } else if (params.field === 'estado' && params.value === "POR VENCER") {
                                return "text_porVencer";
                            } else if (params.field === 'estado' && params.value === "INACTIVA") {
                                return "text_inactiva";
                            }
                        }}
                        pageSizeOptions={[10, 20]}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                    />
                </Paper>
            </Box >
        </HelmetProvider>
    );
}