import React from "react"
import Asistencias from "../pages/Asistencias"
import Nav from "./Nav"
import PrivateRoute from "./privateRoute"
import { Routes, Route } from "react-router-dom";

const RoutesCliente = () => {
    return <Routes>
        <Route
            path="/asistencias"
            element={
                <PrivateRoute>
                    <Nav children={<Asistencias />} />
                </PrivateRoute>
            }
        />
    </Routes>
}

export default RoutesCliente