import React, { useState, useContext } from "react"
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BtnSubmit from "../components/BtnSubmit"
import BtnLoading from "../components/BtnLoading"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import "../styles/login.css"
import logo from "../img/logo.svg"
import Axios from "axios"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../context/auth";

const Login = () => {
    const [data, setData] = useState({
        email: "",
        password: "",
        errorEmail: false,
        errorPassword: false,
        loading: false
    })
    const { email, password, errorEmail, errorPassword, loading } = data
    const [showPass, setShowPass] = useState(false);
    const navigate = useNavigate();

    const { setUser } = useContext(AuthContext)

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handlePassword = () => {
        setShowPass(!showPass);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorEmail: false,
            errorPassword: false,
            loading: true,
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            errorEmail: false,
            errorPassword: false,
            loading: true,
        };

        Object.entries(data).forEach(([key, value]) => {
            if (value.length === 0) {
                if (key === "email") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un correo electrónico";
                } else if (key === "password") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa una contraseña";
                }
                tem["loading"] = false;
                completo = 0;
            }
        });

        if (!completo) {
            setData({
                ...data,
                errorEmail: tem["errorEmail"],
                errorPassword: tem["errorPassword"],
                loading: tem["loading"],
            });
        } else {
            try {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/login", {
                    email: email,
                    password: password
                }, { headers: { "x-api-key": process.env.REACT_APP_API_KEY } }).then((response) => {
                    if (response.data.error === "email") {
                        setData({
                            ...data,
                            errorEmail: response.data.message,
                            errorPassword: false,
                            loading: false
                        });
                    } else if (response.data.error === "password") {
                        setData({
                            ...data,
                            errorEmail: false,
                            errorPassword: response.data.message,
                            loading: false
                        });
                    } else {
                        localStorage.setItem("id", response.data.usuario.id);
                        localStorage.setItem("nombre", response.data.usuario.nombre);
                        localStorage.setItem("apellido", response.data.usuario.apellido);
                        localStorage.setItem("email", response.data.usuario.email);
                        localStorage.setItem("tipo", response.data.usuario.tipo);
                        localStorage.setItem("token", response.data.usuario.token);
                        setUser({
                            id: localStorage.getItem("id"),
                            nombre: localStorage.getItem("nombre"),
                            apellido: localStorage.getItem("apellido"),
                            email: localStorage.getItem("email"),
                            tipo: localStorage.getItem("tipo"),
                            token: localStorage.getItem("token")
                        })
                        if (response.data.usuario.tipo === "prof") {
                            navigate("/asistencias");
                        } else {
                            navigate("/usuarios");
                        }
                    }
                })
            } catch (e) {
                setData({
                    ...data,
                    errorEmail: false,
                    errorPassword: "Error al iniciar sesión",
                    loading: false
                });
            }
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>Iniciar sesión | {process.env.REACT_APP_NAME}</title>
        </Helmet>

        <div className="containerLogin">
            <form className="formLogin" onSubmit={handleSubmit}>
                <img className="logo" src={logo} alt="Logo" />
                <h1>Iniciar sesión</h1>
                <p className="txt_sub">Ingresa a tu cuenta para continuar</p>
                <TextField
                    name="email"
                    type="email"
                    onChange={handleChange}
                    value={email}
                    style={{ marginBottom: "22px" }}
                    label="Correo electrónico"
                    error={errorEmail ? true : false}
                    helperText={errorEmail} />
                <TextField
                    name="password"
                    onChange={handleChange}
                    value={password}
                    type={(showPass) ? "text" : "password"}
                    label="Contraseña"
                    error={errorPassword ? true : false}
                    helperText={errorPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={handlePassword}>
                                    {(showPass) ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} />
                {(loading) ? <BtnLoading texto={"Iniciando sesión..."} /> : <BtnSubmit texto={"Iniciar sesión"} />}
            </form>
        </div>
    </HelmetProvider>

}

export default Login