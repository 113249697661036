import React, { useState, useEffect, useContext } from 'react';
import {
    Box, Paper,
    Stepper,
    Step,
    StepLabel,
    StepContent
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Axios from "axios"
import { AuthContext } from "../context/auth";
import "../styles/modal.css";
import "../styles/historial.css";
import convertirFechaHora from "../hooks/convertirFechaHora"

export default function HistorialObservaciones() {
    const { idAlumno } = useParams();
    const { user } = useContext(AuthContext)
    const [historial, setHistorial] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeStep, setActiveStep] = useState(0);

    const handleStep = (index) => {
        setActiveStep(index);
    };

    useEffect(() => {
        if (user.token !== "") {
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/historial_observaciones/" + idAlumno, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    setHistorial(response.data)
                    setLoading(false)
                }
            );
        }
    }, [user.token, idAlumno])

    return (
        <HelmetProvider>
            <Helmet>
                <title>Historial Observaciones | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <div className="containerHistorialObservaciones">
                        {loading ?
                            <div className="containerLoader">
                                <div className="loader"></div>
                                <p className="txt_loader">Cargando observaciones...</p>
                            </div>
                            : <>
                                <h1>Historial de {historial.alumno}</h1>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {historial.cambios.map((cambio, index) => (
                                        <Step key={cambio.id} onClick={() => handleStep(index)} className="step"
                                            active={true}
                                            completed={index !== (historial.cambios.length - 1)}>
                                            <StepLabel className="step-label" optional={convertirFechaHora(cambio.hora)}>
                                                {cambio.usuario + " Desde: " + cambio.origen}
                                            </StepLabel>
                                            <StepContent>
                                                {cambio.observacion}
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </>}
                    </div>
                </Paper>
            </Box >
        </HelmetProvider>
    );
}