import React, { useState, useEffect, useContext } from 'react';
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import {
    Box, Paper,
    IconButton,
    Tooltip,
    Typography,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
    ListItemText,
    FormHelperText
} from '@mui/material';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Axios from "axios"
import { AuthContext } from "../context/auth";
import "../styles/modal.css";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import BtnSubmit from "../components/BtnSubmit"
import BtnLoading from "../components/BtnLoading"
import Message from "../components/Message";
import convertirFecha from '../hooks/convertirFecha';
import HistoryIcon from '@mui/icons-material/History';
import { useNavigate } from "react-router-dom";
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import convertirHora from "../hooks/convertirHora"
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx-js-style";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Alumnos() {
    const navigate = useNavigate();
    const [creado, setCreado] = useState(false)
    const [alumnos, setAlumnos] = useState([])
    const [horarios, setHorarios] = useState([])
    const [posiAlumno, setPosiAlumno] = useState(0)
    const { user } = useContext(AuthContext)
    const columns = [
        {
            field: 'fullName', headerName: 'Nombre y Apellido', sortable: true, minWidth: 250, flex: 1,
            valueGetter: (value, row) => `${row.nombre || ''} ${row.apellido || ''}`
        },
        {
            field: 'horarioAlumno', headerName: 'Horario', sortable: true, minWidth: 180, flex: 1,
            valueGetter: (value, row) => `${row.horarioAlumno.join(", ") || ''}`
        },
        {
            field: 'sucursal', headerName: 'Sucursal', sortable: true, minWidth: 150, flex: 1,
        },
        { field: 'email', headerName: 'Correo electrónico', minWidth: 250, flex: 1 },
        {
            field: 'fecha', headerName: 'Cumpleaños', minWidth: 170, flex: 1,
            valueGetter: (value, row) => `${convertirFecha(row.fecha) + " " + verificarCumpleaños(row.fecha) || ''}`
        },
        {
            field: 'ruc', headerName: 'RUC', sortable: true, minWidth: 170, flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            flex: 1,
            headerName: 'Actions',
            minWidth: 150,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar alumno"><EditIcon /></Tooltip>}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            var posi = alumnos.findIndex(obj => obj.id === id);
                            setHorarioAlumno(alumnos[posi].horarioAlumno)
                            setPosiAlumno(posi)
                            traerDiasPorSucursal(alumnos[posi].idSucursal)
                            handleOpenModal(alumnos[posi], "Editar alumno");
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Historial de Observaciones"><HistoryIcon /></Tooltip>}
                        label="History"
                        className="textPrimary"
                        onClick={() => {
                            var posi = alumnos.findIndex(obj => obj.id === id);
                            navigate("/historialObservaciones/" + alumnos[posi].id)
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Ver asistencias"><ChecklistRtlIcon /></Tooltip>}
                        label="History"
                        className="textPrimary"
                        onClick={() => {
                            var posi = alumnos.findIndex(obj => obj.id === id);
                            navigate("/asistenciasRegistradas/" + alumnos[posi].id)
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Eliminar alumno"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        onClick={() => eliminarAlumno(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({
        email: "",
        idSucursal: "",
        ruc: "",
        nombre: "",
        apellido: "",
        fecha: "",
        asistencias: "",
        observaciones: "",
        loading: false,
        errorEmail: false,
        errorIdSucursal: false,
        errorNombre: false,
        errorApellido: false,
        errorFecha: false,
        errorAsistencias: false,
        errorObservaciones: false,
        nombreModal: "",
    });
    const { email, observaciones, idSucursal, nombre, apellido, loading,
        fecha, asistencias, errorFecha, errorAsistencias, ruc,
        errorEmail, errorIdSucursal,
        errorNombre, errorApellido, errorObservaciones, nombreModal
    } = data;
    const [sucursales, setSucursales] = useState([])
    const [textoMensaje, setTextoMensaje] = useState("")
    const [horarioAlumno, setHorarioAlumno] = useState([])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function verificarCumpleaños(fecha) {
        const hoy = new Date();
        const fechaIngresada = new Date(fecha);
        // Calcular la diferencia en milisegundos y convertir a días redondeados 
        const diferenciaMilisegundos = fechaIngresada - hoy;
        const diferenciaDias = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
        // Verificar si la fecha ingresada es hoy, mañana o pasado mañana
        if (diferenciaDias >= -1 && diferenciaDias <= 1) {
            return '🎉';
        } else {
            return '';
        }
    }

    const handleOpenModal = (usuario, nombre) => {
        setOpenModal(true)
        setCreado(false);

        if (usuario) {
            usuario["nombreModal"] = nombre;
            setData(usuario);
        } else {
            setData({ ...data, nombreModal: nombre })
        }

    };

    const handleChange = (e) => {
        if (e.target.name === "idSucursal") {
            setData({ ...data, [e.target.name]: e.target.value })
            traerDiasPorSucursal(e.target.value)
            setHorarioAlumno([])
        } else {
            setData({ ...data, [e.target.name]: e.target.value });
        }
    };

    const traerDiasPorSucursal = (idSucursal) => {
        Axios.get(process.env.REACT_APP_API_BASE_URL + "/horarios_dias_disponibles/" + idSucursal, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(
            (response) => {
                var aux = response.data.horarios;
                setHorarios(transformarHorarios(aux))
            }
        );
    }

    function transformarHorarios(horarios) {
        return horarios.map(horario => {
            return { ...horario, "hora": convertirHora(horario.hora) };
        });
    }

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenModal(false)
        setData({
            email: "",
            idSucursal: "",
            nombre: "",
            ruc: "",
            apellido: "",
            fecha: "",
            asistencias: "",
            observaciones: ""
        });
        setHorarioAlumno([])
    };

    const handleChangeHorario = (event) => {
        const {
            target: { value },
        } = event;
        setHorarioAlumno(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const validarEmail = (email) => {
        if (
            /* eslint-disable-next-line */
            /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                email
            )
        ) {
            return true;
        }
    };


    useEffect(() => {
        if (user.token !== "") {
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/alumnos", {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    setAlumnos(response.data.alumnos)
                }
            );
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/sucursales/", {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    setSucursales(response.data.sucursales)
                }
            );
        }
    }, [creado, user.token])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorEmail: false,
            errorIdSucursal: false,
            errorNombre: false,
            errorApellido: false,
            errorFecha: false,
            errorAsistencias: false,
            errorObservaciones: false,
            loading: true,
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            errorEmail: false,
            errorIdSucursal: false,
            errorNombre: false,
            errorApellido: false,
            errorFecha: false,
            errorAsistencias: false,
            errorObservaciones: false,
            loading: true,
        };

        Object.entries(data).forEach(([key, value]) => {
            if (value.length === 0 && (key === "nombre" || key === "apellido" ||
                key === "idSucursal" || key === "asistencias")) {
                if (key === "email") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un correo electrónico";
                } else if (key === "idSucursal") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Selecciona una sucursal"
                } else {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un " + key;
                }
                tem["loading"] = false;
                completo = 0;
            }
        });

        if (!completo) {
            setData({
                ...data,
                errorEmail: tem["errorEmail"],
                errorFecha: tem["errorFecha"],
                errorApellido: tem["errorApellido"],
                errorNombre: tem["errorNombre"],
                errorIdSucursal: tem["errorIdSucursal"],
                errorAsistencias: tem["errorAsistencias"],
                errorObservaciones: tem["errorObservaciones"],
                loading: tem["loading"],
            });
        } else if (email !== "" && !validarEmail(email)) {
            setData({
                ...data,
                errorEmail: "Ingresa un correo valido",
                loading: false,
            });
        } else {
            const idsHorario = horarios
                .filter(elemento => horarioAlumno.includes(`${elemento.dia} - ${elemento.hora}`))
                .map(elemento => elemento.id);

            if (nombreModal === "Editar alumno") {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/editar_alumno", {
                    idAlumno: alumnos[posiAlumno].id,
                    email: email,
                    nombre: nombre,
                    ruc: ruc,
                    apellido: apellido,
                    idsHorario: idsHorario,
                    observaciones: observaciones,
                    fecha: fecha,
                    usuario: user.nombre + " " + user.apellido,
                    asistencias: asistencias
                }, { headers: { Authorization: "Bearer " + user.token } }).then((response) => {
                    if (response.data.message) {
                        setCreado(!creado);
                        setTextoMensaje(response.data.message)
                        handleCloseModal();
                    }
                })
            } else {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/alumnos", {
                    email: email,
                    nombre: nombre,
                    apellido: apellido,
                    ruc: ruc,
                    idsHorario: idsHorario,
                    observaciones: observaciones,
                    fecha: fecha,
                    usuario: user.nombre + " " + user.apellido,
                    asistencias: asistencias
                }, {
                    headers: { Authorization: "Bearer " + user.token }
                }).then((response) => {
                    if (response.data.error === "email") {
                        setData({
                            ...data,
                            errorEmail: response.data.message,
                            errorPassword: false,
                            loading: false
                        });
                    } else {
                        setCreado(!creado);
                        setTextoMensaje(response.data.message)
                        handleCloseModal();
                    }
                })
            }
        }
    }

    const eliminarAlumno = (idAlumno) => {
        if (window.confirm("Esta seguro de eliminar al alumno?")) {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/eliminar_alumno",
                { idAlumno: idAlumno },
                { headers: { Authorization: "Bearer " + user.token } }).then(
                    (response) => {
                        if (response.data.status === "error") {
                            alert("Error: " + response.data.message);
                        } else {
                            setCreado(!creado);
                            setTextoMensaje(response.data.message)
                        }
                    }
                );
        }
    }

    function transformarAlumnosExcel(alumnos) {
        return alumnos.map(alumno => {
            return {
                "nombre": alumno.nombre,
                "apellido": alumno.apellido,
                "email": alumno.email,
                "ruc": alumno.ruc,
                "cumple": convertirFecha(alumno.fecha),
                "observaciones": alumno.observaciones,
                "asistencias": alumno.asistencias,
                "sucursal": alumno.sucursal,
                "horarioAlumno": alumno.horarioAlumno.join(", ")
            };
        });
    }

    const handleExport = () => {
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(transformarAlumnosExcel(alumnos));

        Object.entries(ws).forEach(([key, value]) => {
            if (typeof value !== "string" && key[1] !== "1") {
                //ajuste de las filas de la tabla

                value.s = {
                    border: {
                        top: { style: "thin", color: { auto: 1 } },
                        right: { style: "thin", color: { auto: 1 } },
                        bottom: { style: "thin", color: { auto: 1 } },
                        left: { style: "thin", color: { auto: 1 } },
                    },
                };
            } else if (typeof value !== "string") {
                value.v = value.v.toUpperCase();
                value.s = {
                    border: {
                        top: { style: "thin", color: { auto: 1 } },
                        right: { style: "thin", color: { auto: 1 } },
                        bottom: { style: "thin", color: { auto: 1 } },
                        left: { style: "thin", color: { auto: 1 } },
                    },
                    fill: { fgColor: { rgb: "D1B3AB" } },
                    font: { bold: true, color: { rgb: "000000" } },
                    alignment: { horizontal: "center", vertical: "center" },
                };
            }
        });

        var wscols = [
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 19 },
        ];

        ws["!cols"] = wscols;

        XLSX.utils.book_append_sheet(wb, ws, "Alumnos");

        XLSX.writeFile(wb, "Alumnos_AsuncionPilatesClub.xlsx");
    };

    function EditToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    flexDirection: "row",
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}>
                <div className="tablaHeader">
                    <Typography
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Alumnos
                    </Typography>
                    <div>
                        <Tooltip title="Exportar a Excel">
                            <IconButton onClick={handleExport} style={{ marginRight: "20px" }}>
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Añadir alumno">
                            <IconButton onClick={() => handleOpenModal({ ...data }, "Añadir alumno")}>
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <GridToolbarQuickFilter variant='outlined' size="small" />
            </GridToolbarContainer>
        );
    }


    return (
        <HelmetProvider>
            <Helmet>
                <title>Alumnos | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <Box sx={{ width: "100%" }}>
                {(creado) ? <Message texto={textoMensaje} /> : ""}
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <Dialog
                        className="modal modal_carpetas"
                        open={openModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseModal}
                        fullWidth={true}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {nombreModal}
                            <IconButton
                                onClick={handleCloseModal}
                                aria-label="close"
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent style={{ overflowY: "unset" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="textfield_group">
                                    <TextField
                                        name="nombre"
                                        onChange={handleChange}
                                        value={nombre}
                                        className="inputGroup"
                                        label="Nombre"
                                        error={errorNombre ? true : false}
                                        helperText={errorNombre} />
                                    <TextField
                                        name="apellido"
                                        onChange={handleChange}
                                        value={apellido}
                                        label="Apellido"
                                        error={errorApellido ? true : false}
                                        helperText={errorApellido} />
                                </div>
                                <TextField
                                    name="email"
                                    onChange={handleChange}
                                    value={email}
                                    style={{ marginBottom: "22px" }}
                                    label="Correo electrónico"
                                    error={errorEmail ? true : false}
                                    helperText={errorEmail} />
                                <div className="textfield_group">
                                    <TextField
                                        name="ruc"
                                        className="inputGroup"
                                        onChange={handleChange}
                                        value={ruc}
                                        label="RUC" />
                                    <FormControl
                                        sx={{ minWidth: "230px" }}
                                        error={errorIdSucursal ? true : false}>
                                        <InputLabel id="label-usuario">Sucursal</InputLabel>
                                        <Select
                                            labelId="label-usuario"
                                            label="Sucursal"
                                            name="idSucursal"
                                            value={idSucursal}
                                            onChange={handleChange}
                                        >
                                            <MenuItem key={0} disabled>
                                                Seleccione una sucursal
                                            </MenuItem>
                                            {sucursales.map((sucursal) => (
                                                <MenuItem key={sucursal.id} value={sucursal.id}>
                                                    {sucursal.nombre}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errorIdSucursal}</FormHelperText>
                                    </FormControl>
                                </div>
                                <FormControl style={{ marginBottom: "22px" }}>
                                    <InputLabel id="label-tipo">Horario</InputLabel>
                                    <Select
                                        labelId="label-tipo"
                                        label="Horario"
                                        name="horario"
                                        multiple
                                        multiline
                                        value={horarioAlumno}
                                        maxRows={4}
                                        renderValue={(selected) => (
                                            <p style={{ whiteSpace: "pre-line" }}>{selected.join(", ")}</p>
                                        )}
                                        MenuProps={MenuProps}
                                        onChange={handleChangeHorario}
                                    >
                                        <MenuItem key={0} disabled>
                                            Seleccione un horario
                                        </MenuItem>
                                        {horarios.map((horario) => (
                                            <MenuItem key={horario.id} value={horario.dia + " - " + horario.hora}>
                                                <Checkbox checked={horarioAlumno.includes(horario.dia + " - " + horario.hora)} />
                                                <ListItemText primary={horario.dia + " - " + horario.hora} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <div className="textfield_group">
                                    <TextField
                                        sx={{ minWidth: "230px" }}
                                        name="fecha"
                                        className="inputGroup"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleChange}
                                        value={fecha}
                                        label="Cumpleaños"
                                        error={errorFecha ? true : false}
                                        helperText={errorFecha} />
                                    <TextField
                                        name="asistencias"
                                        onChange={handleChange}
                                        value={asistencias}
                                        label="Asistencias del mes"
                                        error={errorAsistencias ? true : false}
                                        helperText={errorAsistencias} />
                                </div>
                                <TextField
                                    name="observaciones"
                                    onChange={handleChange}
                                    value={observaciones}
                                    label="Observaciones"
                                    multiline
                                    style={{ marginBottom: "22px" }}
                                    maxRows={4}
                                    error={errorObservaciones ? true : false}
                                    helperText={errorObservaciones} />
                                {loading
                                    ? <BtnLoading
                                        texto={(nombreModal === "Editar alumno") ? "Editando..." : "Añadiendo..."} />
                                    : <BtnSubmit
                                        texto={(nombreModal === "Editar alumno") ? "Editar" : "Añadir"} />
                                }
                            </form>
                        </DialogContent>
                    </Dialog>
                    <DataGrid
                        className="tablaAlumnos"
                        autoHeight
                        rows={alumnos}
                        columns={columns}
                        getRowHeight={() => 'auto'}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20]}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                    />
                </Paper>
            </Box >
        </HelmetProvider>
    );
}