export default function convertirHora(hora) {
    var nuevo = hora.replace(":", "");
    nuevo = parseInt(nuevo)

    if (nuevo < 1200) {
        hora += " am"
    } else {
        hora += " pm"
    }

    return hora;
}