import React, { useState, useEffect, useContext } from 'react';
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import {
    Box, Paper,
    Typography,
    Tooltip
} from '@mui/material';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Axios from "axios"
import { AuthContext } from "../context/auth";
import "../styles/modal.css";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Message from "../components/Message";
import convertirFechaHora from "../hooks/convertirFechaHora"
import convertirFecha from "../hooks/convertirFecha"
import { useParams } from 'react-router-dom';

export default function AsistenciasRegistradas() {
    const { idAlumno } = useParams();
    const [alumno, setAlumno] = useState("Alumno")
    const [creado, setCreado] = useState(false)
    const [asistencias, setAsistencias] = useState([])
    const { user } = useContext(AuthContext)
    const columns = [
        {
            field: 'horario', headerName: 'Horario asistencia', sortable: true, minWidth: 190, flex: 1,
            valueGetter: (value, row) => `${row.dia || ''} - ${row.hora || ''}`
        },
        {
            field: 'fecha', headerName: 'Fecha asistencia', sortable: true, minWidth: 190, flex: 1,
            valueGetter: (value, row) => `${convertirFecha(row.fecha) || ''}`
        },
        {
            field: 'sucursal', headerName: 'Sucursal', sortable: true, minWidth: 150, flex: 1
        },
        {
            field: 'usuario', headerName: 'Registrado por', sortable: true, minWidth: 170, flex: 1
        },
        {
            field: 'registro', headerName: 'Registro', sortable: true, minWidth: 160, flex: 1,
            valueGetter: (value, row) => `${convertirFechaHora(row.registro) || ''}`
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Eliminar asistencia"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        onClick={() => {
                            var posi = asistencias.findIndex(obj => obj.id === id);
                            eliminarAsistencia(idAlumno, asistencias[posi].idHorario)
                        }
                        }
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const [textoMensaje, setTextoMensaje] = useState("")

    useEffect(() => {
        if (user.token !== "") {
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/registro_asistencias/" + idAlumno, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    setAsistencias(response.data.asistencias)
                    setAlumno(response.data.alumno)
                }
            );
        }
    }, [creado, user.token, idAlumno])

    const eliminarAsistencia = (idAlumno, idHorario) => {
        if (window.confirm("Esta seguro de eliminar la asistencia?")) {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/eliminar_asistencia",
                { idAlumno: idAlumno, idHorario: idHorario },
                { headers: { Authorization: "Bearer " + user.token } }).then(
                    (response) => {
                        if (response.data.status === "error") {
                            alert("Error: " + response.data.message);
                        } else {
                            setCreado(!creado);
                            setTextoMensaje(response.data.message)
                        }
                    }
                );
        }
    }

    function EditToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    flexDirection: "row",
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}>
                <div className="tablaHeader">
                    <Typography
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Asistencias de {alumno}
                    </Typography>
                </div>
                <GridToolbarQuickFilter variant='outlined' size="small" />
            </GridToolbarContainer>
        );
    }


    return (
        <HelmetProvider>
            <Helmet>
                <title>Asistencias Registradas | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <Box sx={{ width: "100%" }}>
                {(creado) ? <Message texto={textoMensaje} /> : ""}
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <DataGrid
                        autoHeight
                        rows={asistencias}
                        columns={columns}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20]}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                    />
                </Paper>
            </Box >
        </HelmetProvider>
    );
}