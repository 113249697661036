import React, { useState, useEffect, useContext } from 'react';
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import {
    Box, Paper,
    IconButton,
    Tooltip,
    Typography,
    TextField,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
    ListItemText,
    FormHelperText
} from '@mui/material';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Axios from "axios"
import { AuthContext } from "../context/auth";
import "../styles/modal.css";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import BtnSubmit from "../components/BtnSubmit"
import BtnLoading from "../components/BtnLoading"
import Message from "../components/Message";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Usuarios() {
    const navigate = useNavigate();
    const [creado, setCreado] = useState(false)
    const [usuarios, setUsuarios] = useState([])
    const { setUser, user } = useContext(AuthContext)
    const columns = [
        {
            field: 'fullName', headerName: 'Nombre y Apellido', sortable: true, minWidth: 250, flex: 1,
            valueGetter: (value, row) => `${row.nombre || ''} ${row.apellido || ''}`
        },
        { field: 'email', headerName: 'Correo electrónico', minWidth: 250, flex: 1 },
        { field: 'tipo', headerName: 'Tipo', minWidth: 120, flex: 1 },
        {
            field: 'sucursal', headerName: 'Sucursal(es)', sortable: true, minWidth: 180, flex: 1,
            valueGetter: (value, row) => `${row.sucursalesUsuario.join(", ") || ''}`
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            minWidth: 160,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                var posi = usuarios.findIndex(obj => obj.id === id);
                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar usuario"><EditIcon /></Tooltip>}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            setSucursalesUsuario(usuarios[posi].sucursalesUsuario)
                            handleOpenModal(usuarios[posi], "Editar usuario");
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        sx={{ display: (usuarios[posi].tipo === "admin") ? "none" : "bloc" }}
                        icon={<Tooltip title="Avisar horario"><MailOutlineIcon /></Tooltip>}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            avisarProfe(usuarios[posi].email)
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Ver asistencias"><ChecklistRtlIcon /></Tooltip>}
                        label="History"
                        className="textPrimary"
                        onClick={() => {
                            var posi = usuarios.findIndex(obj => obj.id === id);
                            navigate("/asistenciasRegistradasUsuarios/" + usuarios[posi].id)
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Eliminar usuario"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        onClick={() => eliminarUsuario(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({
        email: "",
        password: "",
        nombre: "",
        apellido: "",
        tipo: "",
        loading: false,
        errorEmail: false,
        errorPassword: false,
        errorNombre: false,
        errorApellido: false,
        errorTipo: false,
        nombreModal: "",
    });
    const { email, password, nombre, apellido, loading, errorEmail,
        errorPassword, errorNombre, errorApellido, tipo, errorTipo,
        nombreModal } = data;
    const [showPass, setShowPass] = useState(false);
    const [sucursales, setSucursales] = useState([])
    const [sucursalesUsuario, setSucursalesUsuario] = useState([])
    const [textoMensaje, setTextoMensaje] = useState("")

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleOpenModal = (usuario, nombre) => {
        setOpenModal(true)
        setCreado(false);

        if (usuario) {
            usuario["nombreModal"] = nombre;
            usuario["password"] = ""
            setData(usuario);
        } else {
            setData({ ...data, nombreModal: nombre })
        }

    };

    const handleChange = (e) => {
        if (e.target.name === "activo") {
            setData({ ...data, [e.target.name]: e.target.checked })
        } else {
            setData({ ...data, [e.target.name]: e.target.value });
        }
    };

    const handleChangeSucursales = (event) => {
        const {
            target: { value },
        } = event;
        setSucursalesUsuario(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handlePassword = () => {
        setShowPass(!showPass);
    };

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenModal(false)
        setData({
            nombre: "",
            email: "",
            password: "",
            apellido: "",
            tipo: "",
        });
        setSucursalesUsuario([])
        setShowPass(false)
    };

    const validarEmail = (email) => {
        if (
            /* eslint-disable-next-line */
            /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                email
            )
        ) {
            return true;
        }
    };


    useEffect(() => {
        if (user.token !== "") {
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/usuarios/", {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    setUsuarios(response.data.usuarios)
                }
            );
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/sucursales/", {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    setSucursales(response.data.sucursales)
                }
            );

            if (creado) {
                const timer = setTimeout(() => {
                    setCreado(false);
                }, 4000);
                return () => clearTimeout(timer);
            }
        }
    }, [creado, user.token])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorEmail: false,
            errorPassword: false,
            errorApellido: false,
            errorNombre: false,
            errorTipo: false,
            loading: true,
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            errorEmail: false,
            errorPassword: false,
            errorApellido: false,
            errorNombre: false,
            errorTipo: false,
            loading: true,
        };

        Object.entries(data).forEach(([key, value]) => {
            if (value.length === 0 && (key === "nombre" || key === "apellido" ||
                key === "email" || key === "tipo")) {
                if (key === "email") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un correo electrónico";
                } else {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un " + key;
                }
                tem["loading"] = false;
                completo = 0;
            }
        });

        if (!completo) {
            setData({
                ...data,
                errorEmail: tem["errorEmail"],
                errorPassword: tem["errorPassword"],
                errorApellido: tem["errorApellido"],
                errorNombre: tem["errorNombre"],
                errorTipo: tem["errorTipo"],
                loading: tem["loading"],
            });
        } else if (!validarEmail(email)) {
            setData({
                ...data,
                errorEmail: "Ingresa un correo valido",
                loading: false,
            });
        } else {
            if (nombreModal === "Editar usuario") {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/editar_usuario", {
                    email: email,
                    nombre: nombre,
                    apellido: apellido,
                    password: password,
                    sucursalesUsuario: sucursalesUsuario,
                    tipo: tipo
                }, { headers: { Authorization: "Bearer " + user.token } }).then((response) => {
                    if (response.data.message) {
                        if (email === localStorage.getItem("email")) {
                            localStorage.setItem("nombre", nombre)
                            localStorage.setItem("apellido", apellido)
                            localStorage.setItem("tipo", tipo)
                            setUser({
                                id: localStorage.getItem("id"),
                                nombre: localStorage.getItem("nombre"),
                                apellido: localStorage.getItem("apellido"),
                                email: localStorage.getItem("email"),
                                tipo: localStorage.getItem("tipo"),
                                token: localStorage.getItem("token")
                            })
                        }
                        setCreado(true);
                        setTextoMensaje("Usuario editado!")
                        handleCloseModal();
                    }
                })
            } else {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/usuarios", {
                    email: email,
                    password: password,
                    nombre: nombre,
                    apellido: apellido,
                    tipo: tipo,
                    sucursalesUsuario: sucursalesUsuario
                }, {
                    headers: { Authorization: "Bearer " + user.token }
                }).then((response) => {
                    if (response.data.error === "email") {
                        setData({
                            ...data,
                            errorEmail: response.data.message,
                            errorPassword: false,
                            loading: false
                        });
                    } else {
                        setCreado(true);
                        setTextoMensaje("Usuario creado!")
                        handleCloseModal();
                    }
                })
            }
        }
    }

    const eliminarUsuario = (idUsuario) => {
        if (window.confirm("Esta seguro de eliminar al usuario?")) {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/eliminar_usuario",
                { idUsuario: idUsuario },
                { headers: { Authorization: "Bearer " + user.token } }).then(
                    (response) => {
                        setCreado(true);
                        setTextoMensaje(response.data.message)
                    }
                );
        }
    }

    const avisarProfe = (correoProfe) => {
        if (window.confirm("Esta seguro de enviar el correo?")) {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/aviso_profe",
                { email: correoProfe },
                { headers: { Authorization: "Bearer " + user.token } }).then(
                    (response) => {
                        alert(response.data.message);
                    }
                );
        }
    }

    function EditToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    flexDirection: "row",
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}>
                <div className="tablaHeader">
                    <Typography
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Usuarios
                    </Typography>
                    <Tooltip title="Añadir usuario">
                        <IconButton onClick={() => handleOpenModal({ ...data }, "Añadir usuario")}>
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <GridToolbarQuickFilter variant='outlined' size="small" />
            </GridToolbarContainer>
        );
    }


    return (
        <HelmetProvider>
            <Helmet>
                <title>Usuarios | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <Box sx={{ width: "100%" }}>
                {(creado) ? <Message texto={textoMensaje} /> : ""}
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <Dialog
                        className="modal modal_carpetas"
                        open={openModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseModal}
                        fullWidth={true}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {nombreModal}
                            <IconButton
                                onClick={handleCloseModal}
                                aria-label="close"
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent style={{ overflowY: "unset" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="textfield_group">
                                    <TextField
                                        name="nombre"
                                        onChange={handleChange}
                                        value={nombre}
                                        className="inputGroup"
                                        label="Nombre"
                                        error={errorNombre ? true : false}
                                        helperText={errorNombre} />
                                    <TextField
                                        name="apellido"
                                        onChange={handleChange}
                                        value={apellido}
                                        label="Apellido"
                                        error={errorApellido ? true : false}
                                        helperText={errorApellido} />
                                </div>
                                <TextField
                                    name="email"
                                    onChange={handleChange}
                                    value={email}
                                    style={{ marginBottom: "22px" }}
                                    label="Correo electrónico"
                                    error={errorEmail ? true : false}
                                    inputProps={{
                                        readOnly: (nombreModal === "Editar usuario"),
                                    }}
                                    helperText={errorEmail} />
                                <TextField label={(nombreModal === "Editar usuario") ? "Nueva Contraseña" : "Contraseña"}
                                    name="password"
                                    onChange={handleChange}
                                    type={showPass ? "text" : "password"}
                                    value={password}
                                    error={errorPassword ? true : false}
                                    helperText={errorPassword}
                                    style={{ marginBottom: "22px" }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={handlePassword}>
                                                    {(showPass) ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }} />
                                <FormControl style={{ marginBottom: "22px" }} error={errorTipo ? true : false}>
                                    <InputLabel id="label-tipo">Tipo de usuario</InputLabel>
                                    <Select
                                        labelId="label-tipo"
                                        label="Tipo de usuario"
                                        name="tipo"
                                        value={tipo}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"admin"} key={"admin"}>
                                            Administrador
                                        </MenuItem>
                                        <MenuItem value={"prof"} key={"prof"}>
                                            Profesor
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>{errorTipo}</FormHelperText>
                                </FormControl>
                                <FormControl style={{ marginBottom: "22px" }}>
                                    <InputLabel id="label-tipo">Sucursales del usuario</InputLabel>
                                    <Select
                                        labelId="label-tipo"
                                        label="Sucursales del usuario"
                                        name="sucursalesUsuario"
                                        multiple
                                        value={sucursalesUsuario}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                        onChange={handleChangeSucursales}
                                    >
                                        {sucursales.map((sucursal) => (
                                            <MenuItem key={sucursal.id} value={sucursal.nombre}>
                                                <Checkbox checked={sucursalesUsuario.includes(sucursal.nombre)} />
                                                <ListItemText primary={sucursal.nombre} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {loading
                                    ? <BtnLoading
                                        texto={(nombreModal === "Editar usuario") ? "Editando..." : "Añadiendo..."} />
                                    : <BtnSubmit
                                        texto={(nombreModal === "Editar usuario") ? "Editar" : "Añadir"} />
                                }
                            </form>
                        </DialogContent>
                    </Dialog>
                    <DataGrid
                        autoHeight
                        rows={usuarios}
                        columns={columns}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20]}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                    />
                </Paper>
            </Box >
        </HelmetProvider>
    );
}