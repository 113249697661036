import React, { useState, useEffect, useContext } from 'react';
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import {
    Box, Paper,
    IconButton,
    Tooltip,
    Typography,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide
} from '@mui/material';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Axios from "axios"
import { AuthContext } from "../context/auth";
import "../styles/modal.css";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import BtnSubmit from "../components/BtnSubmit"
import BtnLoading from "../components/BtnLoading"
import Message from "../components/Message";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Sucursales() {
    const [creado, setCreado] = useState(false)
    const [sucursales, setSucursales] = useState([])
    const [posiSucursal, setPosiSucursal] = useState(0)
    const { user } = useContext(AuthContext)
    const columns = [
        {
            field: 'nombre', headerName: 'Nombre', sortable: true, minWidth: 150, flex: 1
        },
        {
            field: 'capacidad', headerName: 'Capacidad', sortable: true, minWidth: 150, flex: 1
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar sucursal"><EditIcon /></Tooltip>}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            var posi = sucursales.findIndex(obj => obj.id === id);
                            setPosiSucursal(posi)
                            handleOpenModal(sucursales[posi], "Editar sucursal");
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Eliminar sucursal"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        onClick={() => eliminarSucursal(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({
        nombre: "",
        capacidad: "",
        loading: false,
        errorNombre: false,
        errorCapacidad: false,
        nombreModal: "",
    });
    const { nombre, capacidad, loading, errorNombre, errorCapacidad,
        nombreModal } = data;
    const [textoMensaje, setTextoMensaje] = useState("")

    const handleOpenModal = (usuario, nombre) => {
        setOpenModal(true)
        setCreado(false);

        if (usuario) {
            usuario["nombreModal"] = nombre;
            usuario["activo"] = (usuario["activo"] === 1)
            setData(usuario);
        } else {
            setData({ ...data, nombreModal: nombre })
        }

    };

    const handleChange = (e) => {
        if (e.target.name === "activo") {
            setData({ ...data, [e.target.name]: e.target.checked })
        } else {
            setData({ ...data, [e.target.name]: e.target.value });
        }
    };

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenModal(false)
        setData({
            nombre: "",
            capacidad: ""
        });
    };

    useEffect(() => {
        if (user.token !== "") {
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/sucursales/", {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    setSucursales(response.data.sucursales)
                }
            );
        }
    }, [creado, user.token])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorCapacidad: false,
            errorNombre: false,
            loading: true,
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            errorCapacidad: false,
            errorNombre: false,
            loading: true,
        };

        Object.entries(data).forEach(([key, value]) => {
            if (value.length === 0 && (key === "nombre" ||
                key === "email" || key === "password")) {
                if (key === "email") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un correo electrónico";
                } else if (key === "password") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa una contraseña";
                } else {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un " + key;
                }
                tem["loading"] = false;
                completo = 0;
            }
        });

        if (!completo) {
            setData({
                ...data,
                errorNombre: tem["errorNombre"],
                errorCapacidad: tem["errorCapacidad"],
                loading: tem["loading"],
            });
        } else {
            if (nombreModal === "Editar sucursal") {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/edit_sucursal", {
                    capacidad: capacidad,
                    nombre: nombre,
                    idSucursal: sucursales[posiSucursal].id
                }, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                }).then((response) => {
                    if (response.data.message) {
                        setCreado(!creado);
                        setTextoMensaje("Sucursal editada!")
                        handleCloseModal();
                    }
                })
            } else {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/sucursales", {
                    nombre: nombre,
                    capacidad: capacidad
                }, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                }).then((response) => {
                    if (response.data.error === "nombre") {
                        setData({
                            ...data,
                            errorNombre: response.data.message,
                            errorCapacidad: false,
                            loading: false
                        });
                    } else {
                        setCreado(!creado);
                        setTextoMensaje("Sucursal creada!")
                        handleCloseModal();
                    }
                })
            }
        }
    }

    const eliminarSucursal = (idSucursal) => {
        if (window.confirm("Esta seguro de eliminar la sucursal?")) {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/eliminar_sucursal/", {
                idSucursal: idSucursal
            }, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    if (response.data.status === "error") {
                        alert("Error: " + response.data.message);
                    } else {
                        setCreado(!creado);
                        setTextoMensaje(response.data.message)
                    }
                }
            );
        }
    }

    function EditToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    flexDirection: "row",
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}>
                <div className="tablaHeader">
                    <Typography
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Sucursales
                    </Typography>
                    <Tooltip title="Añadir sucursal">
                        <IconButton onClick={() => handleOpenModal({ ...data }, "Añadir sucursal")}>
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <GridToolbarQuickFilter variant='outlined' size="small" />
            </GridToolbarContainer>
        );
    }


    return (
        <HelmetProvider>
            <Helmet>
                <title>Sucursales | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <Box sx={{ width: "100%" }}>
                {(creado) ? <Message texto={textoMensaje} /> : ""}
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <Dialog
                        className="modal modal_carpetas"
                        open={openModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseModal}
                        fullWidth={true}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {nombreModal}
                            <IconButton
                                onClick={handleCloseModal}
                                aria-label="close"
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent style={{ overflowY: "unset" }}>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    name="nombre"
                                    onChange={handleChange}
                                    value={nombre}
                                    style={{ marginBottom: "22px" }}
                                    label="Nombre"
                                    error={errorNombre ? true : false}
                                    helperText={errorNombre} />
                                <TextField
                                    name="capacidad"
                                    onChange={handleChange}
                                    value={capacidad}
                                    style={{ marginBottom: "22px" }}
                                    label="Capacidad"
                                    error={errorCapacidad ? true : false}
                                    helperText={errorCapacidad} />
                                {loading
                                    ? <BtnLoading
                                        texto={(nombreModal === "Editar sucursal") ? "Editando..." : "Añadiendo..."} />
                                    : <BtnSubmit
                                        texto={(nombreModal === "Editar sucursal") ? "Editar" : "Añadir"} />
                                }
                            </form>
                        </DialogContent>
                    </Dialog>
                    <DataGrid
                        autoHeight
                        rows={sucursales}
                        columns={columns}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20]}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                    />
                </Paper>
            </Box >
        </HelmetProvider>
    );
}