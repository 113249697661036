import React from "react"
import Nav from "./Nav"
import PrivateRoute from "./privateRoute"
import Usuarios from "../pages/Usuarios"
import Sucursales from "../pages/Sucursales"
import { Route, Routes } from "react-router-dom";
import Dias from "../pages/Dias"
import Horarios from "../pages/Horarios"
import Alumnos from "../pages/Alumnos"
import Cuotas from "../pages/Cuotas"
import HistorialObservaciones from "../pages/HistorialObservaciones"
import Asistencias from "../pages/Asistencias"
import AsistenciasRegistradas from "../pages/AsistenciasRegistradas"
import AsistenciasRegistradasUsuarios from "../pages/AsistenciasRegistradasUsuarios"
import HistorialPagos from "../pages/HistorialPagos"

const RoutesAdmin = () => {
    return <Routes>
        <Route
            path="/usuarios"
            element={
                <PrivateRoute>
                    <Nav children={<Usuarios />} />
                </PrivateRoute>
            }
        />
        <Route
            path="/sucursales"
            element={
                <PrivateRoute>
                    <Nav children={<Sucursales />} />
                </PrivateRoute>
            }
        />
        <Route
            path="/dias"
            element={
                <PrivateRoute>
                    <Nav children={<Dias />} />
                </PrivateRoute>
            }
        />
        <Route
            path="/horarios"
            element={
                <PrivateRoute>
                    <Nav children={<Horarios />} />
                </PrivateRoute>
            }
        />
        <Route
            path="/alumnos"
            element={
                <PrivateRoute>
                    <Nav children={<Alumnos />} />
                </PrivateRoute>
            }
        />
        <Route
            path="/cuotas"
            element={
                <PrivateRoute>
                    <Nav children={<Cuotas />} />
                </PrivateRoute>
            }
        />
        <Route
            path="/historialObservaciones/:idAlumno"
            element={
                <PrivateRoute>
                    <Nav children={<HistorialObservaciones />} />
                </PrivateRoute>
            }
        />
        <Route
            path="/asistencias"
            element={
                <PrivateRoute>
                    <Nav children={<Asistencias />} />
                </PrivateRoute>
            }
        />
        <Route
            path="/asistenciasRegistradas/:idAlumno"
            element={
                <PrivateRoute>
                    <Nav children={<AsistenciasRegistradas />} />
                </PrivateRoute>
            }
        />
        <Route
            path="/asistenciasRegistradasUsuarios/:idUsuario"
            element={
                <PrivateRoute>
                    <Nav children={<AsistenciasRegistradasUsuarios />} />
                </PrivateRoute>
            }
        />
        <Route
            path="/historialPagos/:idAlumno"
            element={
                <PrivateRoute>
                    <Nav children={<HistorialPagos />} />
                </PrivateRoute>
            }
        />
    </Routes>
}

export default RoutesAdmin