import React, { useState, useEffect, useContext } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import {
    Box, Paper,
    Typography
} from '@mui/material';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Axios from "axios"
import { AuthContext } from "../context/auth";
import "../styles/modal.css";
import convertirFechaHora from "../hooks/convertirFechaHora"
import convertirFecha from "../hooks/convertirFecha"
import { useParams } from 'react-router-dom';
import formatearPrecio from "../hooks/FormatearPrecio"

export default function HistorialPagos() {
    const { idAlumno } = useParams();
    const [alumno, setAlumno] = useState("Alumno")
    const [pagos, setPagos] = useState([])
    const { user } = useContext(AuthContext)
    const columns = [
        {
            field: 'fechaInicio', headerName: 'Fecha Inicio', sortable: true, minWidth: 170, flex: 1,
            valueGetter: (value, row) => `${convertirFecha(row.fechaInicio) || ''}`
        },
        {
            field: 'fechaFin', headerName: 'Fecha Fin', sortable: true, minWidth: 150, flex: 1,
            valueGetter: (value, row) => `${convertirFecha(row.fechaFin) || ''}`
        },
        {
            field: 'fechaProxPago', headerName: 'Fecha Prox Pago', sortable: true, minWidth: 190, flex: 1,
            valueGetter: (value, row) => `${convertirFecha(row.fechaProxPago) || ''}`
        },
        {
            field: 'metodoPago', headerName: 'Metodo de Pago', sortable: true, minWidth: 190, flex: 1
        },
        {
            field: 'nroComprobante', headerName: 'Nro comprobante', sortable: true, minWidth: 190, flex: 1
        },
        {
            field: 'precio', headerName: 'Precio', sortable: true, minWidth: 150, flex: 1,
            valueGetter: (value, row) => `Gs. ${formatearPrecio(row.precio.toString()) || ''}`
        },
        {
            field: 'usuario', headerName: 'Registrado por', sortable: true, minWidth: 170, flex: 1
        },
        {
            field: 'hora', headerName: 'Registro', sortable: true, minWidth: 160, flex: 1,
            valueGetter: (value, row) => `${convertirFechaHora(row.hora) || ''}`
        }
    ];

    useEffect(() => {
        if (user.token !== "") {
            Axios.get(process.env.REACT_APP_API_BASE_URL + "/historial_pagos/" + idAlumno, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    setPagos(response.data.pagos)
                    setAlumno(response.data.alumno)
                }
            );
        }
    }, [user.token, idAlumno])

    function EditToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    flexDirection: "row",
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}>
                <div className="tablaHeader">
                    <Typography
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Historial de Pagos de {alumno}
                    </Typography>
                </div>
                <GridToolbarQuickFilter variant='outlined' size="small" />
            </GridToolbarContainer>
        );
    }


    return (
        <HelmetProvider>
            <Helmet>
                <title>Historial de Pagos | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <DataGrid
                        autoHeight
                        rows={pagos}
                        columns={columns}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20]}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                    />
                </Paper>
            </Box >
        </HelmetProvider>
    );
}