import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Paper,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    TextField,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    ListItemText,
    Checkbox,
    Slide,
    FormHelperText,
    Fab
} from '@mui/material';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Axios from "axios"
import { AuthContext } from "../context/auth";
import "../styles/modal.css";
import "../styles/asistencias.css";
import BadgeIcon from '@mui/icons-material/Badge';
import convertirFecha from "../hooks/convertirFecha.js"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Message from "../components/Message";
import BtnSubmit from "../components/BtnSubmit"
import BtnLoading from "../components/BtnLoading"
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from '@mui/icons-material/Cancel';
import convertirHora from "../hooks/convertirHora"
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Asistencias() {
    const [horario, setHorario] = useState([])
    const [creado, setCreado] = useState(false)
    const [alumnos, setAlumnos] = useState([])
    const [horarios, setHorarios] = useState([])
    const [horarioAlumno, setHorarioAlumno] = useState([])
    const [filtroFecha, setFiltroFecha] = useState(obtenerFechaHoySql())
    const [filtroIdSucursal, setFiltroIdSucursal] = useState("")
    const [filtroHorario, setFiltroHorario] = useState("")
    const [textoMensaje, setTextoMensaje] = useState("")
    const [sucursalesUsuario, setSucursalesUsuario] = useState([])
    const [dia, setDia] = useState(obtenerDiaHoy())
    const [openAlumnoModal, setOpenAlumnoModal] = useState(false);
    const [openAsistenciasModal, setOpenAsistenciasModal] = useState(false);
    const [sucursalActual, setSucursalActual] = useState([])
    const [loading, setLoading] = useState(true)
    const { user } = useContext(AuthContext)
    const [data, setData] = useState({
        idSucursal: "",
        nombre: "",
        idAlumno: "",
        email: "",
        ruc: "",
        apellido: "",
        fecha: "",
        fechaAsistencia: "",
        observaciones: "",
        loadingForm: false,
        errorIdAlumno: false,
        errorIdSucursal: false,
        errorHorario: false,
        errorObservaciones: false,
        nombreModal: "",
    });
    const { observaciones, idSucursal, ruc, fechaAsistencia, idAlumno, nombre, apellido, loadingForm,
        fecha, email, errorObservaciones, errorIdAlumno, nombreModal, errorHorario, errorIdSucursal
    } = data;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChange = (e) => {
        if (e.target.name === "filtroIdSucursal") {
            setFiltroIdSucursal(e.target.value)
            if (filtroFecha !== "") {
                traerHorarios(filtroFecha, e.target.value, filtroHorario)
            }
        } else if (e.target.name === "filtroFecha") {
            setFiltroFecha(e.target.value)
            setData({ ...data, "fechaAsistencia": e.target.value })
            if (filtroIdSucursal !== "") {
                traerHorarios(e.target.value, filtroIdSucursal, filtroHorario)
            }
        } else if (e.target.name === "filtroHorario") {
            setFiltroHorario(e.target.value)
            if (filtroIdSucursal !== "") {
                traerHorarios(filtroFecha, filtroIdSucursal, e.target.value)
            }
        } else if (e.target.name === "idSucursal") {
            setData({ ...data, [e.target.name]: e.target.value })
            traerHorariosPorSucursalDia(e.target.value, fechaAsistencia)
            traerAlumnosSucursal(e.target.value)
        } else if (e.target.name === "fechaAsistencia") {
            setData({ ...data, [e.target.name]: e.target.value })
            setHorarioAlumno([])
            traerHorariosPorSucursalDia(idSucursal, e.target.value)
        } else {
            setData({ ...data, [e.target.name]: e.target.value });
        }
    };

    const handleChangeHorario = (event) => {
        const {
            target: { value },
        } = event;
        setHorarioAlumno(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const traerHorarios = (fecha, idSucursal, horario) => {
        setLoading(true)
        setDia(obtenerDiaDeLaSemana(fecha))
        var posiS = sucursalesUsuario.findIndex(obj => obj.id === idSucursal);
        var sucursal = sucursalesUsuario[posiS].nombre

        Axios.post(process.env.REACT_APP_API_BASE_URL + "/traer_asistencias", {
            idUsuario: user.id,
            dia: obtenerDiaDeLaSemana(fecha),
            fecha: fecha,
            sucursal: sucursal,
            horario: horario
        }, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(
            (response) => {
                setHorario(response.data.horario)
                setSucursalesUsuario(response.data.sucursalesUsuario)
                setSucursalActual(response.data.sucursal)
                setLoading(false)
            }
        );
    }

    function obtenerFechaHoySql() {
        const hoy = new Date();
        const anio = hoy.getFullYear();
        const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Meses empiezan desde 0
        const dia = String(hoy.getDate()).padStart(2, '0');
        return `${anio}-${mes}-${dia}`;
    }

    function obtenerDiaDeLaSemana(fecha) {
        const dias = [
            "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"
        ];
        const hoy = new Date(fecha);
        return dias[hoy.getDay()];
    }

    function obtenerDiaHoy() {
        const dias = [
            "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"
        ];
        const hoy = new Date();
        return dias[hoy.getDay()];
    }

    useEffect(() => {
        if (user.token !== "") {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/traer_asistencias", {
                idUsuario: user.id,
                dia: obtenerDiaHoy(),
                fecha: obtenerFechaHoySql(),
                sucursal: "",
                horario: ""
            }, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(
                (response) => {
                    setHorario(response.data.horario)
                    setSucursalesUsuario(response.data.sucursalesUsuario)
                    setSucursalActual(response.data.sucursal)
                    setLoading(false)
                }
            );
        }
    }, [user.token, user.id])

    const traerAlumnosSucursal = (idSucursal) => {
        Axios.get(process.env.REACT_APP_API_BASE_URL + "/alumnos/" + idSucursal, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(
            (response) => {
                setAlumnos(response.data.alumnos)
            }
        );
    }

    const handleOpenAlumnoModal = (usuario, nombre) => {
        setOpenAlumnoModal(true)
        setCreado(false);

        if (usuario) {
            usuario["nombreModal"] = nombre;
            setData(usuario);
        } else {
            setData({ ...data, nombreModal: nombre })
        }
    };

    const handleOpenAsistenciasModal = (usuario, nombre) => {
        setOpenAsistenciasModal(true)
        setCreado(false);

        if (usuario) {
            usuario["fechaAsistencia"] = filtroFecha;
            usuario["nombreModal"] = nombre;
            setData(usuario);
        } else {
            setData({ ...data, nombreModal: nombre })
        }
    };

    const handleCloseAlumnoModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenAlumnoModal(false)
        setData({
            idSucursal: "",
            nombre: "",
            idAlumno: "",
            ruc: "",
            email: "",
            apellido: "",
            fecha: "",
            fechaAsistencia: "",
            observaciones: "",
        });
        setHorarioAlumno([])
    };

    const handleCloseAsistenciasModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenAsistenciasModal(false)
        setData({
            idSucursal: "",
            nombre: "",
            fechaAsistencia: "",
            idAlumno: "",
            email: "",
            apellido: "",
            fecha: "",
            observaciones: "",
        });
        setHorarioAlumno([])
    };

    const traerHorariosPorSucursalDia = (idSucursal, fecha) => {
        console.log(idSucursal, obtenerDiaDeLaSemana(fecha))
        Axios.get(process.env.REACT_APP_API_BASE_URL + "/horarios_dias/" + idSucursal + "/" + obtenerDiaDeLaSemana(fecha), {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(
            (response) => {
                var aux = response.data.horarios;
                setHorarios(transformarHorarios(aux))
            }
        );
    }

    function transformarHorarios(horarios) {
        return horarios.map(horario => {
            return { ...horario, "hora": convertirHora(horario.hora) };
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            loadingForm: true,
            errorIdAlumno: false,
            errorIdSucursal: false,
            errorHorario: false,
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            loadingForm: true,
            errorIdAlumno: false,
            errorIdSucursal: false,
            errorHorario: false,
        };

        Object.entries(data).forEach(([key, value]) => {
            if (value.length === 0 && nombreModal === "Marcar asistencia"
                && (key === "idSucursal" || key === "horario" || key === "idAlumno")) {
                if (key === "idAlumno") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Selecciona un alumno";
                } else if (key === "horario") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Selecciona un horario";
                } else if (key === "idSucursal") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Selecciona una sucursal";
                } else {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un " + key;
                }
                tem["loadingForm"] = false;
                completo = 0;
            }
        });

        if (horarioAlumno.length === 0) {
            tem["errorHorario"] = "Selecciona un horario";
            tem["loadingForm"] = false;
            completo = 0;
        }

        if (!completo) {
            setData({
                ...data,
                errorIdSucursal: tem["errorIdSucursal"],
                errorIdAlumno: tem["errorIdAlumno"],
                errorHorario: tem["errorHorario"],
                loadingForm: tem["loadingForm"],
            });
        } else if (nombreModal === "Editar alumno") {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/editar_observacion", {
                idAlumno: idAlumno,
                observaciones: observaciones,
                usuario: user.nombre + " " + user.apellido,
            }, { headers: { Authorization: "Bearer " + user.token } }).then((response) => {
                if (response.data.message) {
                    setCreado(!creado);
                    setTextoMensaje(response.data.message)
                    handleCloseAlumnoModal();
                }
            })
        } else if (nombreModal === "Marcar asistencia") {
            const idsHorario = horarios
                .filter(elemento => horarioAlumno.includes(`${elemento.dia} - ${elemento.hora}`))
                .map(elemento => elemento.id);

            var posiS = sucursalesUsuario.findIndex(obj => obj.id === idSucursal);

            marcarAsistencia(idAlumno, idsHorario[0], data.fechaAsistencia, sucursalesUsuario[posiS].nombre, sucursalesUsuario[posiS].id)
        }
    }

    const marcarAsistencia = (idAlumno, idHorario, fecha, nombreSucursal, idSucursal) => {
        Axios.post(process.env.REACT_APP_API_BASE_URL + "/marcar_asistencia", {
            idAlumno: idAlumno,
            idHorario: idHorario,
            fecha: fecha,
            sucursal: nombreSucursal,
            usuario: user.nombre + " " + user.apellido,
        }, { headers: { Authorization: "Bearer " + user.token } }).then((response) => {
            if (response.data.message) {
                setCreado(!creado);
                setTextoMensaje(response.data.message)
                traerHorarios(fecha, idSucursal, filtroHorario)
                handleCloseAsistenciasModal();
            }
        })
    }

    const marcarAsistenciaUsuario = (idHorario) => {
        var posiS = sucursalesUsuario.findIndex(obj => obj.nombre === sucursalActual);

        Axios.post(process.env.REACT_APP_API_BASE_URL + "/marcar_asistencia_usuario", {
            idHorario: idHorario,
            fecha: filtroFecha,
            sucursal: sucursalActual,
            idUsuario: user.id,
        }, { headers: { Authorization: "Bearer " + user.token } }).then((response) => {
            if (response.data.message) {
                setCreado(!creado);
                setTextoMensaje(response.data.message)
                traerHorarios(filtroFecha, sucursalesUsuario[posiS].id, filtroHorario)
                handleCloseAsistenciasModal();
            }
        })
    }

    const eliminarAsistencia = (idAlumno, idHorario, idSucursal) => {
        if (window.confirm("Esta seguro de eliminar la asistencia?")) {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/eliminar_asistencia",
                { idAlumno: idAlumno, idHorario: idHorario },
                { headers: { Authorization: "Bearer " + user.token } }).then(
                    (response) => {
                        if (response.data.status === "error") {
                            alert("Error: " + response.data.message);
                        } else {
                            setCreado(!creado);
                            setTextoMensaje(response.data.message)
                            traerHorarios(filtroFecha, idSucursal, filtroHorario)
                        }
                    }
                );
        }
    }

    const eliminarAsistenciaUsuario = (idHorario) => {
        var posiS = sucursalesUsuario.findIndex(obj => obj.nombre === sucursalActual);

        if (window.confirm("Esta seguro de eliminar la asistencia?")) {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/eliminar_asistencia_usuario",
                { idUsuario: user.id, idHorario: idHorario },
                { headers: { Authorization: "Bearer " + user.token } }).then(
                    (response) => {
                        if (response.data.status === "error") {
                            alert("Error: " + response.data.message);
                        } else {
                            setCreado(!creado);
                            setTextoMensaje(response.data.message)
                            traerHorarios(filtroFecha, sucursalesUsuario[posiS].id, filtroHorario)
                        }
                    }
                );
        }
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>Asistencias | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <Box sx={{ width: "100%" }}>
                {(creado) ? <Message texto={textoMensaje} /> : ""}
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <Dialog
                        className="modal modal_carpetas"
                        open={openAlumnoModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseAlumnoModal}
                        fullWidth={true}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {nombreModal}
                            <IconButton
                                onClick={handleCloseAlumnoModal}
                                aria-label="close"
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent style={{ overflowY: "unset" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="textfield_group">
                                    <TextField
                                        name="nombre"
                                        value={nombre}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        className="inputGroup"
                                        label="Nombre" />
                                    <TextField
                                        name="apellido"
                                        value={apellido}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        label="Apellido" />
                                </div>
                                <TextField
                                    name="email"
                                    value={email}
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                    style={{ marginBottom: "22px" }}
                                    label="Correo electrónico" />
                                <div className="textfield_group">
                                    <TextField
                                        name="ruc"
                                        className="inputGroup"
                                        onChange={handleChange}
                                        value={ruc}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        label="RUC" />
                                    <FormControl
                                        sx={{ minWidth: "230px" }}>
                                        <InputLabel id="label-usuario">Sucursal</InputLabel>
                                        <Select
                                            labelId="label-usuario"
                                            label="Sucursal"
                                            name="idSucursal"
                                            inputProps={{
                                                readOnly: true,
                                            }}
                                            value={idSucursal}
                                            onChange={handleChange}
                                        >
                                            <MenuItem key={0} disabled>
                                                Seleccione una sucursal
                                            </MenuItem>
                                            {sucursalesUsuario.map((sucursal) => (
                                                <MenuItem key={sucursal.id} value={sucursal.id}>
                                                    {sucursal.nombre}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <FormControl style={{ marginBottom: "22px" }}>
                                    <InputLabel id="label-tipo">Horario</InputLabel>
                                    <Select
                                        labelId="label-tipo"
                                        label="Horario"
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        name="horario"
                                        multiple
                                        value={horarioAlumno}
                                        renderValue={(selected) => selected.join(", ")}
                                    >
                                        <MenuItem key={0} disabled value={``}>
                                            Seleccione un horario
                                        </MenuItem>
                                        {horarios.map((horario) => (
                                            <MenuItem key={horario.id} value={horario.dia + " - " + horario.hora}>
                                                <Checkbox checked={horarioAlumno.includes(horario.dia + " - " + horario.hora)} />
                                                <ListItemText primary={horario.dia + " - " + horario.hora} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    sx={{ minWidth: "230px", marginBottom: "22px" }}
                                    name="fecha"
                                    className="inputGroup"
                                    type="date"
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    value={fecha}
                                    label="Cumpleaños" />
                                <TextField
                                    name="observaciones"
                                    onChange={handleChange}
                                    value={observaciones}
                                    label="Observaciones"
                                    multiline
                                    style={{ marginBottom: "22px" }}
                                    maxRows={4}
                                    error={errorObservaciones ? true : false}
                                    helperText={errorObservaciones} />
                                {loadingForm
                                    ? <BtnLoading
                                        texto={"Editando..."} />
                                    : <BtnSubmit
                                        texto={"Editar"} />
                                }
                            </form>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        className="modal modal_carpetas"
                        open={openAsistenciasModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseAsistenciasModal}
                        fullWidth={true}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {nombreModal}
                            <IconButton
                                onClick={handleCloseAsistenciasModal}
                                aria-label="close"
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent style={{ overflowY: "unset" }}>
                            <form onSubmit={handleSubmit}>
                                <FormControl
                                    error={errorIdSucursal ? true : false}
                                    sx={{ minWidth: "200px", marginBottom: "22px" }}>
                                    <InputLabel id="label-usuario">Sucursal</InputLabel>
                                    <Select
                                        labelId="label-usuario"
                                        label="Sucursal"
                                        name="idSucursal"
                                        onChange={handleChange}
                                        value={idSucursal}
                                    >
                                        <MenuItem key={0} disabled>
                                            Seleccione una sucursal
                                        </MenuItem>
                                        {sucursalesUsuario.map((sucursal) => (
                                            <MenuItem key={sucursal.id} value={sucursal.id}>
                                                {sucursal.nombre}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{errorIdSucursal}</FormHelperText>
                                </FormControl>
                                <FormControl
                                    sx={{ marginBottom: "22px" }}
                                    error={errorIdAlumno ? true : false}>
                                    <InputLabel id="label-usuario">Alumno</InputLabel>
                                    <Select
                                        labelId="label-usuario"
                                        label="Alumno"
                                        name="idAlumno"
                                        value={idAlumno}
                                        onChange={handleChange}
                                    >
                                        {alumnos.map((alumno) => (
                                            <MenuItem key={alumno.id} value={alumno.id}>
                                                {alumno.nombre + " " + alumno.apellido}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{errorIdAlumno}</FormHelperText>
                                </FormControl>
                                <TextField
                                    sx={{ minWidth: "230px", marginBottom: "22px" }}
                                    name="fechaAsistencia"
                                    className="inputGroup"
                                    onChange={handleChange}
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    value={fechaAsistencia}
                                    label="Fecha" />
                                <FormControl
                                    error={errorHorario ? true : false}
                                    style={{ marginBottom: "22px" }}>
                                    <InputLabel id="label-tipo">Horario</InputLabel>
                                    <Select
                                        labelId="label-tipo"
                                        label="Horario"
                                        name="horario"
                                        value={horarioAlumno}
                                        renderValue={(selected) => selected.join(", ")}
                                        MenuProps={MenuProps}
                                        onChange={handleChangeHorario}
                                    >
                                        <MenuItem key={0} disabled value={``}>
                                            Seleccione un horario
                                        </MenuItem>
                                        {horarios.map((horario) => (
                                            <MenuItem key={horario.id} value={horario.dia + " - " + horario.hora}>
                                                <Checkbox checked={horarioAlumno.includes(horario.dia + " - " + horario.hora)} />
                                                <ListItemText primary={horario.dia + " - " + horario.hora} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{errorHorario}</FormHelperText>
                                </FormControl>
                                {loadingForm
                                    ? <BtnLoading
                                        texto={"Registrando..."} />
                                    : <BtnSubmit
                                        texto={"Registrar"} />
                                }
                            </form>
                        </DialogContent>
                    </Dialog>
                    <div className="containerAsistencias">
                        {(loading) ?
                            <div className="containerLoader">
                                <div className="loader"></div>
                                <p className="txt_loader">Cargando asistencias...</p>
                            </div>
                            : (horario.length > 0) ?
                                <>
                                    <h1>Horarios del dia {dia}, {convertirFecha(filtroFecha)}</h1>
                                    <h3>Sucursal {sucursalActual}</h3>
                                    <div className='textfield_group'>
                                        <FormControl
                                            className="formSelect">
                                            <InputLabel id="label-usuario">Sucursal</InputLabel>
                                            <Select
                                                labelId="label-usuario"
                                                label="Sucursal"
                                                className="inputGroup"
                                                name="filtroIdSucursal"
                                                value={filtroIdSucursal}
                                                onChange={handleChange}
                                            >
                                                {sucursalesUsuario.map((sucursal) => (
                                                    <MenuItem key={sucursal.id} value={sucursal.id}>
                                                        {sucursal.nombre}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            sx={{ minWidth: "230px" }}
                                            name="filtroFecha"
                                            className="inputGroup formSelect"
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            onChange={handleChange}
                                            value={filtroFecha}
                                            label="Fecha" />
                                        <FormControl
                                            className="filtroHorarioAsistencias formSelect">
                                            <InputLabel id="label-usuario">Horario</InputLabel>
                                            <Select
                                                labelId="label-usuario"
                                                label="Horario"
                                                className="inputGroup"
                                                name="filtroHorario"
                                                value={filtroHorario}
                                                onChange={handleChange}
                                            >
                                                <MenuItem key={"todos"} value={"todos"}>
                                                    Todos
                                                </MenuItem>
                                                <MenuItem key={"mañana"} value={"mañana"}>
                                                    Turno Mañana
                                                </MenuItem>
                                                <MenuItem key={"tarde"} value={"tarde"}>
                                                    Turno Tarde
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="containerHorarios">
                                        {horario.map((hor, index) => (
                                            <div key={index}>
                                                {(hor.alumnos.length > 0) ?
                                                    <div>
                                                        <p className="hora">{convertirHora(hor.hora)}</p>
                                                        <div className="containerAlumnos">
                                                            <div className="alumno">
                                                                <AssignmentIndIcon className="iconoAlumno" />
                                                                <div className="infoAlumno" style={{ marginLeft: "20px" }}>
                                                                    <p>{user.nombre + " " + user.apellido}</p>
                                                                    <p className={(hor.usuario.estado === "AUSENTE" ? "ausente" : "presente")}>{hor.usuario.estado}</p>
                                                                </div>
                                                                {(hor.usuario.estado !== "AUSENTE") ?
                                                                    <IconButton onClick={() => {
                                                                        eliminarAsistenciaUsuario(hor.id)
                                                                    }}>
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                    :
                                                                    <IconButton onClick={() => {
                                                                        if (window.confirm("Esta seguro de marcar su asistencia?")) {
                                                                            marcarAsistenciaUsuario(hor.id);
                                                                        }
                                                                    }}>
                                                                        <CheckCircleIcon />
                                                                    </IconButton>
                                                                }
                                                            </div>
                                                            {hor.alumnos.map((alumno, index) => (
                                                                <div className='alumno' key={index}>
                                                                    <IconButton onClick={() => {
                                                                        setHorarioAlumno(alumno.horarioAlumno)
                                                                        handleOpenAlumnoModal(alumno, "Editar alumno")
                                                                    }}>
                                                                        <BadgeIcon className="iconoAlumno" />
                                                                    </IconButton>
                                                                    <div className="infoAlumno">
                                                                        <p>{alumno.nombre + " " + alumno.apellido}</p>
                                                                        <p className={(alumno.estado === "AUSENTE" ? "ausente" : "presente")}>{alumno.estado}</p>
                                                                    </div>
                                                                    {(alumno.estado !== "AUSENTE") ?
                                                                        <IconButton onClick={() => {
                                                                            eliminarAsistencia(alumno.idAlumno, hor.id, alumno.idSucursal)
                                                                        }}>
                                                                            <CancelIcon />
                                                                        </IconButton>
                                                                        :
                                                                        <IconButton onClick={() => {
                                                                            if (window.confirm("Esta seguro de marcar la asistencia de " + alumno.nombre + " " + alumno.apellido + "?")) {
                                                                                marcarAsistencia(alumno.idAlumno, hor.id, filtroFecha, alumno.sucursal, alumno.idSucursal);
                                                                            }
                                                                        }}>
                                                                            <CheckCircleIcon />
                                                                        </IconButton>
                                                                    }
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    : <p className='sinAlumnos'>No hay alumnos para las {convertirHora(hor.hora)}</p>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                    <Fab className="btn_float_asistencia" onClick={() => handleOpenAsistenciasModal({ ...data }, "Marcar asistencia")}>
                                        <HowToRegIcon style={{ color: "#fff", fontSize: 32 }} />
                                    </Fab>
                                </>
                                : <>
                                    <h1>Horarios del dia {dia}, {convertirFecha(filtroFecha)}</h1>
                                    <h3>Sucursal {sucursalActual}</h3>
                                    <div className='textfield_group'>
                                        <FormControl
                                            className="formSelect">
                                            <InputLabel id="label-usuario">Sucursal</InputLabel>
                                            <Select
                                                labelId="label-usuario"
                                                label="Sucursal"
                                                className="inputGroup"
                                                name="filtroIdSucursal"
                                                value={filtroIdSucursal}
                                                onChange={handleChange}
                                            >
                                                {sucursalesUsuario.map((sucursal) => (
                                                    <MenuItem key={sucursal.id} value={sucursal.id}>
                                                        {sucursal.nombre}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            sx={{ minWidth: "230px" }}
                                            name="filtroFecha"
                                            className="inputGroup formSelect"
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            onChange={handleChange}
                                            value={filtroFecha}
                                            label="Fecha" />
                                    </div>
                                    <div className="containerEmpty">
                                        <p className="txt">No hay horarios disponibles</p>
                                    </div>
                                </>
                        }
                    </div>
                </Paper>
            </Box >
        </HelmetProvider>
    );
}