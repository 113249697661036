import { ThemeProvider, createTheme } from "@mui/material/styles";
import { HashRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Login from "./pages/Login"
import { AuthContext } from "./context/auth"
import { useContext } from "react"
import RoutesAdmin from './components/RoutesAdmin';
import RoutesProf from './components/RoutesProf';

function App() {
  const { user } = useContext(AuthContext)

  const theme = createTheme({
    palette: {
      primary: {
        main: "#E8BA97",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          {(user && user.tipo === "admin")
            ? <Route path="*" element={<RoutesAdmin />} />
            : <Route path="*" element={<RoutesProf />} />}
        </Routes>
      </HashRouter>
    </ThemeProvider >
  );
}

export default App;
